import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import * as api from '../../services/api_service'
import Invests from '../../components/Invests';
import { useLocation, useNavigate } from 'react-router-dom';

function Investments() {

  const navigate = useNavigate()
  const { state } = useLocation()
  const [investments, setInvestments] = useState([])
  const [isFetchingInvestments, setisFetchingInvestments] = useState(true)

  useEffect(() => {
    async function getInvestments() {
      try {
        const resp = await api.investments();
        console.log('all', resp.data.data)
        setisFetchingInvestments(false)
        setInvestments(resp.data.data)
      } catch (error) {
        setisFetchingInvestments(false)
        console.log(error)
      }
    }

    getInvestments()
  }, [])

  useEffect(() => {
    if (state && Object.keys(state).length > 0 && state.toast === true) {
      toast.success('Stacking successful. You can watch your profit progress on your dashboard.', state.toastConfig)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (
    <div className="col-12 col-lg-8">
      <div className="row">
        <div className="col-12 mb-4">
          <div className='h5 text-left' style={{ float: 'left' }}>Investments</div>
          <div className='text-right mb-3'>
            <button onClick={() => { navigate('/user/investment/packages') }} className='btn btn-sm me-3 btn-tertiary'>Select New package &nbsp;<i className='fa fa-plus'></i></button>
          </div>

          <Invests investments={investments} isFetchingInvestments={isFetchingInvestments} paginate={20} />
        </div>
      </div>
    </div>
  )
}

export default Investments