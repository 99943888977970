import React from 'react'
import logo from '../assets/image/logo.png'

function Loader() {
  return (
    <div className='pageLoader'>
        <img src={logo} alt="Themesberg logo" style={{'width': '90px'}} /><br />
        <i className='fa fa-circle-notch fa-spin'></i>
    </div>
  )
}

export default Loader