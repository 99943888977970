import React, { useEffect, useMemo, useRef, useState } from 'react'
import Transacts from '../../components/Transacts';
import * as api from '../../services/api_service'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';

import usdtimg from '../../assets/image/usdt.png'
import ethimg from '../../assets/image/eth.svg'
import btcimg from '../../assets/image/btc.png'
import ltcimg from '../../assets/image/ltc.png'
import bnbimg from '../../assets/image/bnb.png'

function Deposit() {

  const [activeMethod, setActiveMethod] = useState({})
  const [addresses, setAddresses] = useState([])
  const [deposits, setDeposits] = useState([])
  const proof = useRef()
  const copyText = useRef()
  const [isFetchingAddresses, setIsFetchingAddresses] = useState(true)
  const [isDepositing, setIsDepositing] = useState(false)
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [amount, setAmount] = useState('');
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  useEffect(() => {
    async function fetchDatas() {
      try {
        const req = await Promise.all([
          api.deposits(),
          api.paymentAddresses()
        ])

        console.log(req)
        setAddresses(req[1].data.data)
        setDeposits(req[0].data.data)
        setIsFetchingAddresses(false)
      } catch (error) {
        console.log(error)
        setIsFetchingAddresses(false)
      }
    }

    fetchDatas()
  }, [])

  const changeHandler = (e) => {
    const file_ = e.target.files[0];
    if (file_) {
      if (!file_.type.match(imageMimeType)) {
        alert("Image mime type is not valid");
        return;
      }
      setFile(file_);
    } else {
      setFile(null)
    }
  }
  useEffect(() => {
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
          // console.log(result)
        }
      }
      fileReader.readAsDataURL(file);
    } else {
      setFileDataURL(null)
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);


  const btc = useMemo(() => {
    if (addresses.length > 0) {
      let btc_ = addresses.filter((e) => e.abbreviation === 'BTC')
      return btc_.length > 0 ? btc_[0] : null
    } else {
      return null
    }
  }, [addresses])

  const eth = useMemo(() => {
    if (addresses.length > 0) {
      let eth_ = addresses.filter((e) => e.abbreviation === 'ETH')
      return eth_.length > 0 ? eth_[0] : null
    } else {
      return null
    }
  }, [addresses])

  const bnb = useMemo(() => {
    if (addresses.length > 0) {
      let bnb_ = addresses.filter((e) => e.abbreviation === 'BNB')
      return bnb_.length > 0 ? bnb_[0] : null
    } else {
      return null
    }
  }, [addresses])

  const ltc = useMemo(() => {
    if (addresses.length > 0) {
      let ltc_ = addresses.filter((e) => e.abbreviation === 'LTC')
      return ltc_.length > 0 ? ltc_[0] : null
    } else {
      return null
    }
  }, [addresses])

  const usdterc = useMemo(() => {
    if (addresses.length > 0) {
      let usdterc_ = addresses.filter((e) => e.abbreviation === 'USDT (ERC20)')
      return usdterc_.length > 0 ? usdterc_[0] : null
    } else {
      return null
    }
  }, [addresses])

  const usdttrc = useMemo(() => {
    if (addresses.length > 0) {
      let usdttrc_ = addresses.filter((e) => e.abbreviation === 'USDT (TRC20)')
      return usdttrc_.length > 0 ? usdttrc_[0] : null
    } else {
      return null
    }
  }, [addresses])

  useEffect(() => {
    addresses.length > 0 && setActiveMethod(addresses[0])
  }, [addresses])

  function handleCopy() {
    console.log(copyText.current.value)
    copyText.current?.select();
    document.execCommand("copy");
    copyText.current.focus()
    toast.success("Copied to clipboard", toastConfig)
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  async function handleDeposit() {
    // console.log('file', proof.current.files[0])
    // console.log('amount', amount)
    setIsDepositing(true)
    try {
      const resp = await api.deposit({ amount: amount, account: activeMethod.abbreviation, address: activeMethod.address, image: file })
      setDeposits((dep) => [...dep, resp.data.data])
      proof.current.value = ''
      setAmount('')
      setFile(null)
      setFileDataURL(null)
      setIsDepositing(false)
      toast.success("Deposit Request Sent", { ...toastConfig, autoClose: 8000 })
    } catch (error) {
      console.log(error)
      setFile(null)
      setAmount('')
      setFileDataURL(null)
      setIsDepositing(false)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  return (
    <div className="col-12 col-lg-8">
      <div className="row">
        <div className="col-md-7 mb-3">
          <div className="card border-gray-300">
            <div className="card-body">
              <div className='h5 mb-4'>Deposit</div>
              <div style={{ fontSize: '14px' }} className='mt-2 mb-2'>Select Payment Method</div>
              <div className='row mb-2'>

                <div className='col-6'>
                  <div className="card" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : btc ? '#fff' : 'rgb(240,240,240)', border: btc && activeMethod.name === btc.name ? 'solid 2px #000' : '' }} onClick={() => btc && !isFetchingAddresses && setActiveMethod(btc)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={btcimg} alt="btc" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>Bitcoin</div>
                          {!isFetchingAddresses ? btc !== null ? <div style={{ fontSize: '11px' }}>BTC</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-2" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : eth ? '#fff' : 'rgb(240,240,240)', border: eth && activeMethod.name === eth.name ? 'solid 2px #000' : '' }} onClick={() => eth && !isFetchingAddresses && setActiveMethod(eth)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={ethimg} alt="eth" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>Ethereum</div>
                          {!isFetchingAddresses ? eth !== null ? <div style={{ fontSize: '11px' }}>ETH</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-2" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : bnb ? '#fff' : 'rgb(240,240,240)', border: bnb && activeMethod.name === bnb.name ? 'solid 2px #000' : '' }} onClick={() => bnb && !isFetchingAddresses && setActiveMethod(bnb)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={bnbimg} alt="bnb" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>BNB</div>
                          {!isFetchingAddresses ? bnb !== null ? <div style={{ fontSize: '11px' }}>BNB</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <div className="card" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : ltc ? '#fff' : 'rgb(240,240,240)', border: ltc && activeMethod.name === ltc.name ? 'solid 2px #000' : '' }} onClick={() => ltc && !isFetchingAddresses && setActiveMethod(ltc)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={ltcimg} alt="ltc" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>Litecoin</div>
                          {!isFetchingAddresses ? ltc !== null ? <div style={{ fontSize: '11px' }}>LTC</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-2" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : usdterc ? '#fff' : 'rgb(240,240,240)', border: usdterc && activeMethod.name === usdterc.name ? 'solid 2px #000' : '' }} onClick={() => usdterc && !isFetchingAddresses && setActiveMethod(usdterc)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={usdtimg} alt="usdt" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>USDT</div>
                          {!isFetchingAddresses ? usdterc !== null ? <div style={{ fontSize: '11px' }}>ERC20</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mt-2" style={{ backgroundColor: isFetchingAddresses ? 'rgb(240,240,240)' : usdttrc ? '#fff' : 'rgb(240,240,240)', border: usdttrc && activeMethod.name === usdttrc.name ? 'solid 2px #000' : '' }} onClick={() => usdttrc && !isFetchingAddresses && setActiveMethod(usdttrc)}>
                    <div className="card-body">
                      <div className='d-flex'>
                        <img src={usdtimg} alt="usdt" style={{ width: '35px', marginRight: '10px' }} />
                        <div>
                          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>USDT</div>
                          {!isFetchingAddresses ? usdttrc !== null ? <div style={{ fontSize: '11px' }}>TRC20</div> : <div style={{ fontSize: '10px' }}>Not available</div> : <Skeleton width={50} highlightColor='#999' />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="card">
            <div className="card-body">
              <div style={{ fontSize: '15px' }} className='mt-4 mb-2'>
                <div>Selected Payment Method</div>
                <div style={{ fontWeight: 'bold' }}>{activeMethod.name && `${activeMethod.name}`}</div>
                {activeMethod.address &&
                  <div className="mt-3">
                    <div className="mb-3">
                      <label htmlFor="addressInput" style={{ fontSize: '14px' }}>Payment Address</label>
                      <div className='text-tertiary' style={{fontSize: '12px', display: 'block', marginTop: -6, cursor: 'pointer'}} onClick={() => { activeMethod.name && handleCopy() }} >Click To Copy Payment Address To Clipboard</div>
                      <div className="input-group" onClick={() => { activeMethod.name && handleCopy() }}>
                        <input type="text" className="form-control" id="addressInput" style={{ fontSize: '11px' }} value={activeMethod.address} ref={copyText} aria-label="Search" aria-describedby="basic-addon2" readOnly />
                        <span className="input-group-text" id="basic-addon2"><span className="fas fa-clipboard"></span></span>
                      </div>
                    </div>

                    <div className="mb-2">
                      <label htmlFor="amountInput" style={{ fontSize: '14px' }}>Enter Amount Sent</label>
                      <div className='text-tertiary' style={{fontSize: '12px', display: 'block', marginTop: -7}} >Send this amount to the {activeMethod.name} address above.</div>
                      <div className="input-group">
                        <input type="number" className="form-control" id="amountInput" placeholder='0.00' value={amount} onChange={(e) => setAmount(e.target.value)} style={{ fontSize: '13px' }} />                        
                        <span className="input-group-text" id="basic-addon2"><span className="fas fa-dollar"></span></span>
                      </div>
                    </div>

                    <div className='row mt-3 mb-4'>                      

                      <div className="col-12">
                        <span style={{ fontSize: '14px' }}>Payment Proof</span>
                        <input type="file" accept='.png, .jpg, .jpeg' onChange={changeHandler} style={{ display: 'none' }} ref={proof} />
                        {!fileDataURL ?
                          <div onClick={() => proof.current.click()} className='mt-1' style={{ width: '100%', height: '100px', padding: '10px 20px 5px 20px', 'border': 'dotted', display: 'relative' }}>
                            <div style={{ fontSize: '13px' }}>Click to upload proof of payment</div>
                            <div style={{ display: 'inline-block' }} className='p-2'><i className='fa fa-pencil' /></div>
                          </div> :
                          <div onClick={() => proof.current.click()} className='mt-1' style={{ height: '150px', backgroundImage: `url(${fileDataURL})`, backgroundSize: 'cover', backgroundPosition: '0px', backgroundRepeat: 'no-repeat', width: '100%', padding: '20px 20px', 'border': 'dotted', display: 'relative' }}>
                            {/* <div>Click to upload proof of payment</div> */}
                            <div className='shadow-lg' style={{ display: 'inline-block', float: 'right', padding: '4px 8px', borderRadius: '100px', backgroundColor: 'rgba(255,255,255,0.5)' }} ><i className='fa fa-pencil' /></div>
                          </div>
                        }
                      </div>

                      {/* <div className="col-6">
                        {activeMethod.image ?
                          <div>
                            <span style={{ fontSize: '13px' }}>QR (Payment Address)</span>
                            <img src={activeMethod.image} alt="qrcode" />
                          </div>
                          : <span style={{ fontSize: '13px' }}>No QR Code</span>}
                      </div> */}
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <button className="btn btn-primary" onClick={handleDeposit} style={{ width: '100%' }} disabled={!file || amount === '' || amount < 10 || isDepositing}>Submit</button>
                      </div>
                    </div>

                  </div>
                }
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 mb-4">
          <Transacts transactions={deposits} isFetchingTransactions={isFetchingAddresses} paginate={6} type='deposit' />
        </div>
      </div>
    </div>
  )
}

export default Deposit