import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import UseAuth from '../../hooks/UseAuth';
import AppHelpers from '../../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import * as api from '../../services/api_service'
import Transacts from '../../components/Transacts';
import NewWallet from '../../components/NewWallet';

function Withdrawal() {

  const { user, setUser, isAuthenticated } = UseAuth()
  const { currencyFormat, ucfirst } = AppHelpers()
  const navigate = useNavigate()
  const [withdrawals, setWithdrawals] = useState([])
  const [wallets, setWallets] = useState([])
  const [amount, setAmount] = useState('')
  const [activeWallet, setActiveWallet] = useState('')
  const [type, setType] = useState('wallet')
  const [isFetching, setIsFetching] = useState(true)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  useEffect(() => {
      async function getInvestments() {
        try {
          const resp = await Promise.all([
            api.addresses(),
            api.withdrawals()
          ]);
          console.log('all', resp)
          setIsFetching(false)
          setWallets(resp[0].data.data)
          setWithdrawals(resp[1].data.data)
        } catch (error) {
          setIsFetching(false)
          console.log(error)
        }
      }

      getInvestments()
  }, [])

  async function handleWithdraw() {
    setIsWithdrawing(true)
    try {
      const resp = await api.withdraw(type, { wallet: activeWallet, amount: amount })
      setWithdrawals((with_) => [...with_, resp.data.data])
      setIsWithdrawing(false)
      type === 'referral' ? setUser((user_) => ({ ...user_, referral_balance: (user_.referral_balance - resp.data.data.amount) })) : setUser((user_) => ({ ...user_, balance: (user_.balance - resp.data.data.amount) }))
      toast.success("Withdrawal Request Sent", { theme: "dark" })
    } catch (error) {
      console.log(error)
      setIsWithdrawing(false)
      toast.error(error.response.data.message, { theme: "dark" })
    }
  }

  return (
    <div className="col-12 col-lg-8">
      <div className="row">

        <div className="col-md-6 mb-3 mt-2">
        <div className='h5 mb-4'>Select Withdrawal Balance </div>
          <div className={type === 'wallet' ? "card mb-2" : "card border-gray-300 mb-2"} style={{ border: type === 'wallet' && 'solid 2px #000' }} onClick={() => setType('wallet')}>
            <div className="card-body d-block d-md-flex align-items-center">
              <div className="icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0"><span className="fas fa-wallet"></span></div>
              <div><span className="d-block h6 fw-normal">Wallet Balance</span>
                <h5 className="h3 fw-bold mb-1">{isAuthenticated ? currencyFormat(user.balance) : <Skeleton width={150} />}</h5>
                <div className="small mt-2"><small>Investments are added after it elapses</small></div>
              </div>
            </div>
          </div>

          <div className={type === 'referral' ? "card mb-2" : "card border-gray-300"} style={{ border: type === 'referral' && 'solid 2px #000' }} onClick={() => setType('referral')}>
            <div className="card-body d-block d-md-flex align-items-center">
              <div className="icon icon-shape icon-md icon-shape-primary rounded-circle me-3 mb-4 mb-md-0"><span className="fas fa-file-invoice-dollar"></span></div>
              <div><span className="d-block h6 fw-normal">Referral Balance</span>
                <h5 className="h3 fw-bold mb-1">{isAuthenticated ? currencyFormat(user.referral_balance) : <Skeleton width={150} />}</h5>
                <div className="small mt-2"><small>Referral Bonus from downline</small></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <div style={{ fontSize: '17px' }} className='mb-4'><b>Wallet WIthdrawal</b></div>
              <div style={{ fontSize: '14px' }} className='mt-2 mb-2 row'>
                <div className="col-6 text-left">
                  <NewWallet setWallets={setWallets} />
                </div>
                <div className="col-6 text-right">
                  <button onClick={() => navigate('/user/profile')} className='btn btn-link btn-sm text-tertiary' ><i className='fa fa-pencil' /> Manage Wallets</button>
                </div>
              </div>
              <div className='row'>
                <div className="col-12 mt-2">

                  <div className="mb-2">
                    <label htmlFor="walletInput" style={{ fontSize: '14px' }}>Select Wallet</label>
                    <div className="input-group">
                      <input type="hidden" value={activeWallet} />
                      <select className="form-control" id="walletInput" onChange={(e) => setActiveWallet(e.target.value)} style={{ fontSize: '13px' }} >
                        <option value=''>-- Select Wallet --</option>
                        {
                          wallets.length > 0 && wallets.map((e, k) => (
                            <option value={e.id} key={k}>{`${ucfirst(e.account)} - ${e.address}`}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label htmlFor="amountInput" style={{ fontSize: '14px' }}>Enter Amount</label>
                    <div className="input-group">
                      <input type="number" className="form-control" id="amountInput" placeholder='0.00' value={amount} onChange={(e) => setAmount(e.target.value)} style={{ fontSize: '13px' }} />
                    </div>
                  </div>

                  <button className='btn btn-tertiary mt-2' onClick={() => handleWithdraw()} disabled={activeWallet === '' || isWithdrawing || amount === '' || (type === 'wallet' && parseFloat(amount) > user.balance) || (type === 'referral' && parseFloat(amount) > user.referral_balance)} style={{ width: '100%' }}>Submit</button>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="row mt-3">
        <div className="col-12 mb-4">
          <Transacts transactions={withdrawals} isFetchingTransactions={isFetching} paginate={6} type='withdraw' />
        </div>
      </div>

    </div>
  )
}

export default Withdrawal