import React, { useEffect, useMemo, useState } from 'react'
import AppHelpers from '../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'

function Invests({ investments, paginate = 50, isFetchingInvestments }) {

    const { currencyFormat } = AppHelpers()
    const [filteredInvestments, setFilteredInvestments] = useState([])
    const [paginatedInvestments, setPaginatedInvestments] = useState([])
    const [sort, setSort] = useState('all')
    const [activePage, setActivePage] = useState(0)

    useEffect(() => {
        setFilteredInvestments(investments)
        setPaginatedInvestments(investments.slice(0, paginate))
    }, [investments, paginate])

    useEffect(() => {
        setPaginatedInvestments(filteredInvestments.slice(0, paginate))
    }, [filteredInvestments, paginate])

    const allPages = useMemo(() => {
        return Math.ceil(filteredInvestments.length / paginate)
    }, [filteredInvestments, paginate])

    function handleSort(data) {
        if (data === 'month') {
            let now = new Date()

            let filter_ = investments.filter((e) => {
                let newDate = new Date(e.created_at)
                return newDate.getMonth() === now.getMonth() && newDate.getFullYear() === now.getFullYear()
            })

            setFilteredInvestments(filter_)
        } else {
            setFilteredInvestments(investments)
        }
        setSort(data)
        setActivePage(0)
    }

    function handlePaginate(data) {
        let newPageStart = data * paginate
        setPaginatedInvestments(filteredInvestments.slice(newPageStart, newPageStart + paginate))
        setActivePage(data)
    }


    return (
        <div className="card border-gray-300">
            <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                <div className="d-block">
                    <div className="h5 fw-normal text-gray mt-2">Recent Investments</div>
                </div>
                <div className="d-flex ms-auto">
                    <button className={sort === 'month' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('month')}>Month</button>
                    <button className={sort === 'all' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('all')}>See All</button>
                </div>
            </div>
            <div className="card-body">
                {/* <SkeletonTheme baseColor="#eee" highlightColor="#999">
                                <p>
                                    <Skeleton count={3} />
                                </p>
                            </SkeletonTheme> */}
                {
                    paginatedInvestments.length > 0 ?
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Date <br /> Invested</th>
                                            <th>Investemnt Package</th>
                                            <th>Investment <br /> Capital</th>
                                            <th>Expected Earnings</th>
                                            <th>Elapse <br /> Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paginatedInvestments.map((e, k) => (
                                                <tr key={k}>
                                                    <td>{e.created_at}</td>
                                                    <td>{e.plan?.name}</td>
                                                    <td>{currencyFormat(e.capital)}</td>
                                                    <td>{currencyFormat(e.earning)}</td>
                                                    <td>{e.elapse}</td>
                                                    <td className={e.status === 2 ? 'bg-success text-white' : 'bg-warning text-white'}>{e.status === 2 ? 'Completed' : 'Ongoing'}</td>
                                                    
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            
                            {allPages > 0 && filteredInvestments.length > paginate &&
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center mt-5">
                                            <li className="page-item"><button className="page-link" onClick={() => activePage > 0 && handlePaginate(activePage - 1)}>Previous</button></li>
                                            {
                                                Array.from(Array(allPages)).map((e, k) => (
                                                    <li key={k} className={activePage === k ? 'page-item active' : 'page-item'} ><button className="page-link" onClick={() => handlePaginate(k)}>{k + 1}</button></li>
                                                ))
                                            }
                                            <li className="page-item"><button className="page-link" onClick={() => activePage + 1 < allPages && handlePaginate(activePage + 1)}>Next</button></li>

                                        </ul>
                                    </nav>
                                }
                        </div> : isFetchingInvestments ? <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Date Invested</th>
                                        <th>Package</th>
                                        <th>Capital</th>
                                        <th>Elapse</th>
                                        <th>Status</th>
                                        <th>Total Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(Array(8)).map((e, k) => (
                                        <tr key={k}>
                                            <td><Skeleton /></td><td><Skeleton /></td><td><Skeleton /></td>
                                            <td><Skeleton /></td><td><Skeleton /></td><td><Skeleton /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                            <div>
                                <p>No result found</p>
                            </div>
                }
            </div>
        </div>


    )
}

export default Invests