import React, { useEffect, useRef, useState } from 'react'
import useAuth from '../../../hooks/UseAuth';
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/image/logo.png'

function Email() {

  const isRendered = useRef(false);
  const navigate = useNavigate()
  const { authenticateEmailOTP, isAuthenticated, isAuthenticating, user, authError, setAuthError, authMiddleware, sendOtp, token, logout } = useAuth()

  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState([])

  useEffect(() => {
    authMiddleware()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token, isAuthenticating])

  useEffect(() => {

    if (isRendered.current === true) {
      if (Object.keys(user).length > 0 && user.twofa === 0) {
        return navigate("/user/dashboard", { replace: true })
      }

      if (Object.keys(user).length > 0 && user.twofa !== 0 && user.email_verified_at !== null) {
        return navigate("/user/dashboard", { replace: true })
      }
    } else {
      isRendered.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token, user])

  async function authUserOTP() {
    setOtpError([])

    const resp = await authenticateEmailOTP({ otp: otp })

    if (Object.keys(resp.error).length === 0) {
      return navigate('/user/dashboard', { replace: true })
    } else {
      console.log('Otp Errors', resp.error)
      resp.error.errors.otp && setOtpError((err) => [...err, resp.error.errors.otp])
    }
  }

  async function resendOtp($type = 'email') {
    const resp = await sendOtp($type);
    if (Object.keys(resp.error).length === 0) {
      alert('OTP resent')
    }
  }

  return (
    <div>

      <section className="min-vh-100 d-flex align-items-center section-image overlay-soft-dark"
        data-background="" style={{ 'backgroundColor': 'rgba(245,245,245,1)' }}>
        <div className="container">
          <center style={{ marginTop: '-70px' }}>
            <img src={logo} alt="logo" className='auth-logo mb-4' />
          </center>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="text-center text-md-center mb-3 mt-md-0 text-primary">
                <h1 className="mb-0 h3">Two Factor Authenticator</h1>
              </div>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div
                className="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
                <form action="#">
                  <div className="form-group mb-4"><label htmlFor="email">OTP</label>
                    <div className="input-group"><span className="input-group-text" id="basic-addon3"><span
                      className="fas fa-envelope"></span></span> <input type="email" className="form-control"
                        placeholder='OTP' onChange={(e) => {
                          setOtp(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setOtpError([]);
                        }} id="email" required /></div>

                    {otpError.map((err, k) => (<span key={k} style={{ color: 'red' }}>{err}<br /></span>))}<br />
                    <span>A one time token has been sent to <b>{user.email}</b>. <br />
                    </span>
                  </div>
                  <div className="d-grid">
                    {!isAuthenticating ? <button onClick={authUserOTP} className="btn btn-primary">Authenticate</button> : <button className="btn btn-primary" disabled>Authenticating ...</button>}

                  </div>

                  {Object.keys(authError).length > 0 && <p style={{ color: 'red' }}>{authError.message}</p>}
                  <br />
                </form>
                <div>
                  Did not receive it ? <button className='btn btn-primary btn-sm' onClick={() => resendOtp()} disabled={isAuthenticating}>Resend</button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4"><span className="fw-normal">
                  <button onClick={() => logout()} className="btn btn-md btn-link"><b style={{ 'textDecoration': 'underline' }}>Logout</b></button></span></div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Email