
import React, { useEffect } from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { ToastContainer } from 'react-toastify';
import useAuth from '../hooks/UseAuth';
import AppHelpers from '../hooks/AppHook';
import Loader from '../components/Loader';
import profilePic from '../assets/image/profile/default.png'
import logo from '../assets/image/logo.png'
import Balance from '../components/Balance.js';

function UserLayout() {

    // const isRendered = useRef(false);
    // const navigate = useNavigate()

    const { ucfirst, currencyFormat } = AppHelpers()
    const { user, isAuthenticated, isAuthenticating, emailVerifiedMiddleware, authMiddleware, token, logout } = useAuth()

    useEffect(() => {
        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating])

    useEffect(() => {
        emailVerifiedMiddleware()
    }, [user, emailVerifiedMiddleware])

    function closeMobileNav() {
        document.getElementsByClassName("collapse show")[0].childNodes[0].childNodes[0].childNodes[1].childNodes[0].click()
    }

    return (
        <>

            {!isAuthenticated || isAuthenticating ? <Loader /> :
                <>
                    <ToastContainer />
                    <header className="header-global">
                        <nav id="navbar-main" aria-label="Primary navigation" className="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light navbar-transparent navbar-theme-secondary">
                            <div className="container position-relative">
                                <Link className="navbar-brand me-lg-5" to="/user/dashboard"><img className="navbar-brand-dark" src={logo} alt="Logo light" /> <img className="navbar-brand-light" src={logo} style={{ 'width': '40px', 'height': '45px' }} alt="Logo dark" /></Link>
                                <div className="navbar-collapse collapse me-auto" id="navbar_global">
                                    <div className="navbar-collapse-header">
                                        <div className="row">
                                            <div className="col-6 collapse-brand">
                                                <Link to="/user/dashboard" onClick={closeMobileNav}><img src={logo} alt="Site logo" /></Link>
                                            </div>
                                            <div className="col-6 collapse-close">
                                                <Link to="#navbar_global" className="fas fa-times" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" title="close" aria-label="Toggle navigation"></Link>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="navbar-nav navbar-nav-hover align-items-lg-center show-mobile-only">
                                        <li className="nav-item">
                                            <NavLink to="/user/dashboard" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Overview <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/copy-trader" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Copy Trader <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/market" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Market + <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/trade-history" onClick={closeMobileNav} className="nav-link d-flex" >
                                                My Trade History <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <NavLink to='#' className="nav-link d-flex" data-bs-toggle="dropdown" id="investmentsDropdown" aria-expanded="false">
                                                Investments <span
                                                    className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span>
                                            </NavLink>

                                            <div className="dropdown-menu dropdown-menu-lg" aria-labelledby="investmentsDropdown" style={{ paddingLeft: '20px' }}>
                                                <div className="col-auto px-0">
                                                    <div className="list-group list-group-flush">
                                                        <NavLink to="/user/investments" onClick={closeMobileNav} className="font-small fw-bold d-flex" style={{ paddingLeft: '20px', borderLeft: 'solid 2px #999' }} >
                                                            Overview <span
                                                                className="icon icon-xs ms-auto"></span>
                                                        </NavLink>
                                                        <NavLink to="/user/investment/packages" onClick={closeMobileNav} className="font-small fw-bold d-flex mt-2" style={{ paddingLeft: '20px', borderLeft: 'solid 2px #999' }} >
                                                            Packages <span
                                                                className="icon icon-xs ms-auto"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/transaction/deposit" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Wallet Deposit <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/transactions" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Transactions <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/transaction/withdraw" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Wallet Withdrawal <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/downlines" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Downlines <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user/transfer_asset" onClick={closeMobileNav} className="nav-link d-flex" >
                                                Transfer Funds <span
                                                    className="icon icon-xs ms-auto"></span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to='#' className="nav-link d-flex" data-bs-toggle="dropdown" id="accountDropdown" aria-expanded="false">
                                                My Account <span
                                                    className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span>
                                            </NavLink>

                                            <div className="dropdown-menu dropdown-menu-lg mb-3" aria-labelledby="accountDropdown" style={{ paddingLeft: '20px' }}>
                                                <div className="col-auto px-0">
                                                    <div className="list-group list-group-flush">
                                                        <NavLink to="/user/profile" onClick={closeMobileNav} className="font-small fw-bold d-flex" style={{ paddingLeft: '20px', borderLeft: 'solid 2px #999' }} >
                                                            Profile <span
                                                                className="icon icon-xs ms-auto"></span>
                                                        </NavLink>
                                                        <NavLink to="/user/security" onClick={closeMobileNav} className="font-small fw-bold d-flex mt-2" style={{ paddingLeft: '20px', borderLeft: 'solid 2px #999' }} >
                                                            Security <span
                                                                className="icon icon-xs ms-auto"></span>
                                                        </NavLink>
                                                        <NavLink to="/user" onClick={logout} className="font-small fw-bold d-flex mt-2" style={{ paddingLeft: '20px', borderLeft: 'solid 2px #999' }} >
                                                            Sign Out <span
                                                                className="icon icon-xs ms-auto"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <div className="col-auto px-0">
                                            <div className="list-group list-group-flush"></div>
                                            <NavLink to={'/user/tickets'} className="p-4 list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4"><span
                                                className="icon icon-sm"><span className="fas fa-microphone-alt"></span></span>
                                                <div className="ms-4"><span className="d-block font-small fw-bold mb-0">Support</span> <span
                                                    className="small">Need help? Ask us!</span></div>
                                            </NavLink>
                                        </div>

                                    </ul>

                                    <ul className="navbar-nav navbar-nav-hover align-items-lg-center show-not-mobile">

                                        <li className="nav-item">
                                            <NavLink to="/user/dashboard" className="nav-link" >
                                                Dashboard
                                            </NavLink>
                                        </li>

                                        <li className="nav-item dropdown"><NavLink to="#" className="nav-link dropdown-toggle" id="dashboardDropdown" aria-expanded="false" data-bs-toggle="dropdown">Account <span
                                            className="fas fa-angle-down nav-link-arrow ms-1"></span></NavLink>
                                            <div className="dropdown-menu dropdown-megamenu-sm px-0 py-2 p-lg-4" aria-labelledby="dashboardDropdown">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h6 className="d-block mb-3 text-primary">My Investments</h6>
                                                        <ul className="list-style-none mb-4">
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/investments">Overview</NavLink></li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/investment/packages">Packages</NavLink></li>
                                                        </ul>
                                                        <h6 className="d-block mb-3 text-primary">My Account</h6>
                                                        <ul className="list-style-none mb-4">
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/profile">Profile</NavLink>
                                                            </li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/security">Security</NavLink></li>

                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/transfer_asset">Transfer Asset</NavLink></li>
                                                        </ul>
                                                        <h6 className="d-block mb-3 text-primary">Referral</h6>
                                                        <ul className="list-style-none">
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/downlines">Downlines</NavLink></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="d-block mb-3 text-primary">Trader</h6>
                                                        <ul className="list-style-none mb-4">
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/market">Market +</NavLink></li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/trade-history">My Trade History</NavLink></li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/copy-trader">Copy Trader</NavLink></li>
                                                        </ul>
                                                        <h6 className="d-block mb-3 text-primary">Transactions</h6>
                                                        <ul className="list-style-none mb-4">
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/transactions">Overview</NavLink></li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/transaction/deposit">Deposit</NavLink></li>
                                                            <li className="mb-2 megamenu-item"><NavLink className="megamenu-link" to="/user/transaction/withdraw">Withdraw</NavLink></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="nav-item dropdown"><Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="supportDropdown" aria-expanded="false">Support <span
                                            className="fas fa-angle-down nav-link-arrow ms-1"></span></Link>
                                            <div className="dropdown-menu dropdown-menu-lg" aria-labelledby="supportDropdown">
                                                <div className="col-auto px-0">
                                                    <div className="list-group list-group-flush">
                                                        {/* <Link to="https://themesberg.com/docs/bootstrap-5/pixel/getting-started/quick-start/" target="_blank" rel='noreferrer' className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4"><span
                                                    className="icon icon-sm"><span className="fas fa-file-alt"></span></span>
                                                    <div className="ms-4"><span className="d-block font-small fw-bold mb-0">Documentation<span
                                                        className="badge badge-sm badge-secondary ms-2">v3.1</span></span> <span className="small">Examples
                                                            and guides</span></div>
                                                </Link> */}
                                                        <Link to="/user/tickets" className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4"><span
                                                            className="icon icon-sm"><span className="fas fa-microphone-alt"></span></span>
                                                            <div className="ms-4"><span className="d-block font-small fw-bold mb-0">Support</span> <span
                                                                className="small">Need help? Ask us!</span></div>
                                                        </Link></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex align-items-center">
                                    {/* <button className="btn btn-outline-gray-100 d-none d-lg-inline me-md-3"><span className="fas fa-book me-2"></span></button> */}
                                    <Balance />
                                    <button className="navbar-toggler ms-2" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation"><span
                                            className="navbar-toggler-icon"></span></button>
                                </div>
                            </div>
                        </nav>
                    </header>

                    <main>

                        <div className="section section-lg pt-5 pt-md-7 bg-gray-200">
                            <div className="container">
                                <div className="row pt-5 pt-md-0">
                                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                        <div className="card border-gray-300 p-2">
                                            <div className="card-header bg-white border-0 text-center d-flex flex-row flex-lg-column align-items-center justify-content-center px-1 px-lg-4">
                                                <div className="profile-thumbnail dashboard-avatar mx-lg-auto me-3"><div style={{ backgroundImage: isAuthenticated ? `url(${user.image})` : `url(${profilePic})` }} className="card-img-top profile-image-div rounded-circle border-white" alt="Bonnie Green Portrait" /></div><span className="h5 my-0 my-lg-3 me-3 me-lg-0">{isAuthenticated ? `Hi, ${ucfirst(user.firstname)}!` : <Skeleton width={150} />}</span>
                                                {isAuthenticated && <button className="btn btn-gray-300 btn-xs" onClick={() => logout()}><span className="me-2"><span
                                                    className="fas fa-sign-out-alt"></span></span>Sign Out</button>}
                                            </div>
                                            <div className="card-body p-2 d-none d-lg-block">
                                                <div className="list-group dashboard-menu list-group-sm"><NavLink to={'/user/dashboard'} className="d-flex list-group-item border-0 list-group-item-action">Overview <span
                                                    className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/copy-trader'} className="d-flex list-group-item border-0 list-group-item-action">Copy Trader<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/market'} className="d-flex list-group-item border-0 list-group-item-action">Market +<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/trade-history'} className="d-flex list-group-item border-0 list-group-item-action">My Trade History<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/investments'} className="d-flex list-group-item border-0 list-group-item-action">My Investments<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/investment/packages'} className="d-flex list-group-item border-0 list-group-item-action">Investment Packages<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/transaction/deposit'} className="d-flex list-group-item border-0 list-group-item-action">Wallet Deposit<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/transactions'} className="d-flex list-group-item border-0 list-group-item-action">Transactions<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/transaction/withdraw'} className="d-flex list-group-item border-0 list-group-item-action">Wallet Withdrawal<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/downlines'} className="d-flex list-group-item border-0 list-group-item-action">Downlines<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/transfer_asset'} className="d-flex list-group-item border-0 list-group-item-action">Transfer Asset<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/profile'} className="d-flex list-group-item border-0 list-group-item-action">My Account<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/security'} className="d-flex list-group-item border-0 list-group-item-action">Security<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span> </NavLink>
                                                    <NavLink to={'/user/tickets'} className="d-flex list-group-item border-0 list-group-item-action border-0">Contact Support<span
                                                        className="icon icon-xs ms-auto"><span className="fas fa-chevron-right"></span></span></NavLink></div>
                                            </div>
                                        </div>
                                    </div>

                                    <Outlet />

                                </div>
                            </div>
                        </div>
                    </main>
                    <div className='bg-primary text-white p-3' style={{'textAlign': 'center'}}>
                        {process.env.REACT_APP_APP_NAME} &copy; {new Date().getFullYear()}
                    </div>
                    {/* <footer className="footer pt-6 pb-5 bg-primary text-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5"><img className="navbar-brand-dark mb-4" height="35" src={logo} alt="Logo light" />
                                    <p>All deposit should be made directly to {process.env.REACT_APP_APP_NAME} trading wallet provided on your dashboard, {process.env.REACT_APP_APP_NAME} wont aknowlegde fund transfer from third party agent or be accountable for loss of deposit made to any account managers or refer</p>
                                    <ul className="social-buttons mb-5 mb-lg-0">
                                        <li><span
                                            className="m-2 fab fa-twitter"></span></li>
                                        <li><span className="m-2 fab fa-facebook"></span></li>
                                        <li><span
                                            className="m-2 fab fa-github"></span></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-md-3 mb-5 mb-lg-0"><span className="h5">{process.env.REACT_APP_APP_NAME}</span>
                                    <ul className="footer-links mt-2">
                                        <li><Link to={'/user/profile'}>Profile</Link></li>
                                        <li><Link to={'/user/security'}>Security</Link></li>
                                        <li><Link to={'/user/transfer_asset'}>Transfer Assets</Link></li>
                                        <li><Link to={'/user/tickets'}>Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-4 mb-5 mb-lg-0"><span className="h5">Subscribe</span>
                                    <p className="text-muted font-small mt-2">Join our mailing list. We write rarely, but only the best content.</p>
                                    <div className="form-row mb-2">
                                        <div className="col-12 d-grid"><button className="btn btn-tertiary" data-loading-text="Sending"><span>Subscribe</span></button></div>
                                    </div>
                                    <p className="text-muted font-small m-0">We’ll never share your details. See our Privacy Policy</p>
                                </div>
                            </div>
                            <hr className="bg-secondary my-3 my-lg-5" />
                            <div className="row">
                                <div className="col mb-md-0">

                                    <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                                        <p className="fw-normal font-small mb-0">Copyright © {process.env.REACT_APP_APP_NAME} 2016-<span className="current-year">2021</span>. All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                </>

            }


        </>
    )
}

export default UserLayout