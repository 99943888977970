import React, { useEffect, useMemo, useState } from 'react'
import AppHelpers from '../hooks/AppHook';
import Skeleton from 'react-loading-skeleton'

function Invests({ transactions: transacts, paginate = 50, isFetchingTransactions, type }) {

    const { currencyFormat, ucfirst } = AppHelpers()
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const [paginatedTransactions, setPaginatedTransactions] = useState([])
    const [sort, setSort] = useState('all')
    const [activePage, setActivePage] = useState(0)

    const transactions = useMemo(() => {        
        const trans_ = type === 'all' ? transacts : transacts.filter((e) => (
            e.type === type
        ))
        return trans_.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }, [transacts, type])

    useEffect(() => {
        setFilteredTransactions(transactions)
        setPaginatedTransactions(transactions.slice(0, paginate))
    }, [transactions, paginate])

    useEffect(() => {
        setPaginatedTransactions(filteredTransactions.slice(0, paginate))
    }, [filteredTransactions, paginate])

    const allPages = useMemo(() => {
        return Math.ceil(filteredTransactions.length / paginate)
    }, [filteredTransactions, paginate])

    function handleSort(data) {
        if (data === 'month') {
            let now = new Date()

            let filter_ = transactions.filter((e) => {
                let newDate = new Date(e.created_at)
                return newDate.getMonth() === now.getMonth() && newDate.getFullYear() === now.getFullYear()
            })

            setFilteredTransactions(filter_)
        } else {
            setFilteredTransactions(transactions)
        }
        setSort(data)
        setActivePage(0)
    }

    function handlePaginate(data) {
        let newPageStart = data * paginate
        setPaginatedTransactions(filteredTransactions.slice(newPageStart, newPageStart + paginate))
        setActivePage(data)
    }


    return (
        <div className="card border-gray-300">
            <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                <div className="d-block">
                    <div className="h5 fw-normal text-gray mt-2">{type === 'withdraw' ? 'Withdrawal' : ucfirst(type)} Transactions</div>
                </div>
                <div className="d-flex ms-auto">
                    <button className={sort === 'month' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('month')}>Month</button>
                    <button className={sort === 'all' ? 'btn btn-sm me-3 btn-tertiary' : 'btn border-gray-300 btn-sm me-3 btn-white'} onClick={() => handleSort('all')}>All</button>
                </div>
            </div>
            <div className="card-body">
                {
                    paginatedTransactions.length > 0 ?
                        <div style={{ width: '100%', height: 'auto' }}>
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Method</th>
                                            <th>Address</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paginatedTransactions.map((e, k) => (
                                                <tr key={k}>
                                                    <td>{e.created_at}</td>
                                                    <td>{currencyFormat(e.amount)}</td>
                                                    <td>{e.account}</td>
                                                    <td>{e.address}</td>
                                                    <td className={e.status === 1 ? 'bg-warning text-white' : e.status === 2 ? 'bg-success text-white' : 'bg-error text-white'}>
                                                        {e.status === 1 ? 'Pending' : e.status === 2 ? 'Success' : 'Failed'}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            
                            {allPages > 0 && filteredTransactions.length > paginate &&
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center mt-5">
                                            <li className="page-item"><button className="page-link" onClick={() => activePage > 0 && handlePaginate(activePage - 1)}>Previous</button></li>
                                            {
                                                Array.from(Array(allPages)).map((e, k) => (
                                                    <li key={k} className={activePage === k ? 'page-item active' : 'page-item'} ><button className="page-link" onClick={() => handlePaginate(k)}>{k + 1}</button></li>
                                                ))
                                            }
                                            <li className="page-item"><button className="page-link" onClick={() => activePage + 1 < allPages && handlePaginate(activePage + 1)}>Next</button></li>

                                        </ul>
                                    </nav>
                                }
                        </div> : isFetchingTransactions ? <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Method</th>
                                        <th>Account</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(Array(8)).map((e, k) => (
                                        <tr key={k}>
                                            <td><Skeleton /></td><td><Skeleton /></td><td><Skeleton /></td>
                                            <td><Skeleton /></td><td><Skeleton /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                            <div>
                                <p>No result found</p>
                            </div>
                }
            </div>
        </div>


    )
}

export default Invests